<template>
  <!-- Overlay with dynamic z-index based on modal stack -->
  <div
    tabindex="-1"
    :style="{ zIndex: 50 + modalStack.length }"
    class="fixed inset-0 bg-black bg-opacity-40 flex items-end md:items-center justify-center"
    v-if="isVisible"
  >
    <!-- Modal content with slide-up animation -->
    <transition
      name="slide-up"
      enter-active-class="transform transition-transform duration-300"
      enter-from-class="translate-y-full"
      enter-to-class="translate-y-0"
      leave-active-class="transform transition-transform duration-300"
      leave-from-class="translate-y-0"
      leave-to-class="translate-y-full"
      mode="out-in"
    >
      <!-- Modal Content -->
      <div
        v-if="isVisible"
        key="modal-content"
        :style="{ zIndex: 51 + modalStack.length }"
        class="relative w-full md:w-auto max-h-[75vh] md:max-h-[90vh] h-3/4 md:h-auto md:m-auto bg-white dark:bg-slate-900 rounded-t-lg md:rounded-lg shadow dark:shadow-gray-800 overflow-auto"
      >
        <!-- Close Button -->
        <button
          type="button"
          class="absolute z-1 top-2 right-2 text-gray-400 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 hover:text-slate-900 rounded-full text-sm p-1.5 inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          @click="closeModal"
        >
          <mdicon name="close" size="20" />
        </button>
        <!-- Modal Content Area -->
        <div>
          <div class="relative">
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, watchEffect, onMounted, onUnmounted } from 'vue';

const modalStack = ref([]);

const props = defineProps({
  modelValue: Boolean,
  width: String,
});

const emit = defineEmits(['update:modelValue']);
const isVisible = ref(props.modelValue);

watchEffect(() => {
  if (props.modelValue) {
    modalStack.value.push(Date.now());
    window.history.pushState(null, '', window.location.href); // Add a state to the history
  } else {
    modalStack.value.pop();
  }
  isVisible.value = props.modelValue;
  toggleBodyScroll(isVisible.value);
});

function closeModal() {
  emit('update:modelValue', false);
  if (modalStack.value.length === 0) {
    window.history.back(); // Trigger the back action when closing the modal
  }
}

// Prevent body scrolling when modal is open
function toggleBodyScroll(isModalOpen) {
  if (isModalOpen) {
    document?.body.classList.add('overflow-hidden');
  } else {
    document?.body.classList.remove('overflow-hidden');
  }
}

// Listen for back button events on mobile or browser
function handlePopState() {
  if (isVisible.value) {
    closeModal();
  }
}

// Mount and unmount listeners for popstate
onMounted(() => {
  window.addEventListener('popstate', handlePopState);
});

onUnmounted(() => {
  window.removeEventListener('popstate', handlePopState);
  document.body.classList.remove('overflow-hidden');
  modalStack.value = []; // Clear the modal stack
});
</script>
